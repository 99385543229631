.typewriter h1, 
.typewriter h3 {
    opacity: 0%;
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
    fadeinout 3.75s .25s infinite
}

@keyframes typing {
    from { width: 0 }
    50% { width: 100% }
    to { width: 0 }
}

@keyframes fadeinout {
    from { opacity: 0% }
    50% { opacity: 100% }
    to { opacity: 0% }
}

.gradient {
    background: linear-gradient( white, #6e91ba);
    background-size: 300% 300%;
    animation:
    gradientWash 5s ease infinite
}

@keyframes gradientWash {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}